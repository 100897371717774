































































import api from "@/api/index";
import { Vue, Component } from "vue-property-decorator";
import { AttachmentDto, AttachmentHostType, StudentFeedbackRecordDto } from "@/api/appService";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";

@Component({
  name: "FeedbackRecordDetail",
  components: { AttachmentsView, Ueditor }
})
export default class ContactDetail extends Vue {
  labelStyle = {
    fontSize: "16px"
  };
  dataId = 0;
  feedbackRecordInfo: StudentFeedbackRecordDto = {};
  feedbackRecordId = 0;
  gradeList: any[] = [];
  attachments: AttachmentDto[] = [];
  hostType = AttachmentHostType.StudentFeedback;
  loading = true;
  get formatGradeGetter() {
    return this.formatType(this.feedbackRecordInfo.grade!, this.gradeList);
  }

  get term() {
    return this.feedbackRecordInfo.term === 1 ? "第一学期" : "第二学期";
  }

  async created() {
    this.feedbackRecordId = Number(this.$route.params.id);
    this.dataId = this.feedbackRecordId;
    this.feedbackRecordInfo = await this.getFeedbackRecordInfoById();
    await this.getGradeList();
    this.attachments = await api.attachmentService.getAttachmentsByIds({
      ids: this.dataId.toString()
    });
    this.loading = false;
  }

  handleDownload(item: AttachmentDto) {
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }

  formatType(type: string, typeList: any[]) {
    let result = "";
    typeList.forEach((item) => {
      if (item.value == type) {
        result = item.text;
        return;
      }
    });
    return result;
  }

  getFeedbackRecordInfoById() {
    return api.studentFeedbackRecord.get({
      id: this.feedbackRecordId
    });
  }

  isImage(item: AttachmentDto) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }

  async getGradeList() {
    this.gradeList = await api.enumService.getValues({ typeName: "StudentGrade" });
  }

  back() {
    this.$router.go(-1);
  }
}
